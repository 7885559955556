import { render, staticRenderFns } from "./my-downloads-component.vue?vue&type=template&id=e97175ec&"
import script from "./my-downloads-component.ts?vue&type=script&lang=ts&"
export * from "./my-downloads-component.ts?vue&type=script&lang=ts&"
import style0 from "./my-downloads-component.vue?vue&type=style&index=0&id=e97175ec&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports