import { render, staticRenderFns } from "./login-redirect-module.vue?vue&type=template&id=8a10a1e6&"
import script from "./login-redirect-module.ts?vue&type=script&lang=ts&"
export * from "./login-redirect-module.ts?vue&type=script&lang=ts&"
import style0 from "./login-redirect-module.vue?vue&type=style&index=0&id=8a10a1e6&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports